export class GetPaymentDetailsReducer {
  reduce = (result) => {
    return {
      regularPrice: result.body.prices[0],
      premiumPrice: result.body.prices[1],
      paymentGateways: result.body.gateways,
      paymentGatewayDetails: result.body.gateway_details,
    };
  };
}
