import { useState, useMemo, useCallback } from "react";
import { GetApplicationNameHandler } from "../services/api/GetApplicationName/GetApplicationNameHandler";
import { GetPaymentDetailsHandler } from "../services/api/GetPaymentDetails/GetPaymentDetailsHandler";
import { GetApplicationInfoHandler } from "../services/api/GetApplicationInfo/GetApplicationInfoHandler";
import { getCookie, setCookies } from "../components/utils/cookies";
import { getUtmsCookies } from "../components/utils/SEM/UTM/checkUtmParams";
import {
  getParamInUrl,
  arrayToQueryParams,
  isObjectEmpty,
  getUrlParams,
} from "../components/utils";
import { navigate } from "gatsby";
import { loadIframeUrl } from "../components/Form/Pay/utilities";

const defaultConfig = {
  redirect: { allow: false, to: "/" },
};

const useGetApplication = ({ redirect } = defaultConfig) => {
  const [loading, setLoading] = useState(true);
  const [iframeLoaderContainer, setIframeLoaderContainer] = useState(false);
  const [application, setApplication] = useState({
    applicationUser: "",
    applicationEmail: "",
    applicationName: "",
    applicationNumber: "",
    applicationReference: "",
    applicationPrice: {
      id: "",
      name: "",
      amount: "",
    },
    emptyGatways: true,
    paymentGatewayDetails: [],
    paymentGateways: {},
    gatewaysStructure: [],
  });

  const applicationId = useMemo(() => {
    const { order, pdf, tag } = getUrlParams();
    const orderCookie = getCookie("address_token");

    if (order && pdf) {
      setCookies("address_token", {
        ...orderCookie,
        order: order,
        applicationId: order,
        pdfId: pdf,
      });
    }

    if (tag) {
      setCookies("tag_param", { tag: tag });
    }

    const param_id = getParamInUrl("id");
    const param_order = getParamInUrl("order");
    return param_id || param_order || orderCookie.order;
  }, []);

  const redirectTo = () => {
    if (redirect.allow) {
      if (typeof redirect.to === "function") {
        redirect.to();
      } else {
        navigate(redirect.to);
      }
    }
  };

  const getApplicationName = () => {
    return new Promise(async (resolve, reject) => {
      const applicationNameHandler = new GetApplicationNameHandler({});
      let data = {
        reference: applicationId,
      };
      try {
        const response = await applicationNameHandler.customAction(data);

        return resolve(response);
      } catch (error) {
        redirectTo();
        throw reject(error);
      }
    });
  };

  const getApplicationInfo = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const GetApplicationInfo = new GetApplicationInfoHandler({});
        const response = await GetApplicationInfo.customAction({ applicationId });

        if (response.status !== "registered") {
          redirectTo();
          throw reject({ ...response, applicationStatus: response.status });
        } else {
          return resolve(response);
        }
      } catch (error) {
        redirectTo();
        throw reject(error);
      }
    });
  };

  const getApplicationPaymentDetails = () => {
    return new Promise(async (resolve, reject) => {
      let paymentDetailsParamsArray = [];
      let excludedParams = [
        "applicationId",
        "error",
        "tag",
        "utm_source",
        "utm_campaign",
        "utm_medium",
        "utm_content",
        "gclid",
        "msclkid",
      ];

      paymentDetailsParamsArray.push({ applicationId: applicationId });
      if (JSON.stringify(getUtmsCookies()) !== "{}")
        paymentDetailsParamsArray.push({
          utm_params: encodeURIComponent(JSON.stringify(getUtmsCookies())),
        });
      if (!isObjectEmpty(getCookie("tag_param")))
        paymentDetailsParamsArray.push({ tag: getCookie("tag_param").tag });

      for (const [key, value] of Object.entries(getUrlParams())) {
        if (!excludedParams.includes(key)) {
          paymentDetailsParamsArray.push({ [key]: value });
        }
      }

      try {
        const applicationPaymentDetailsHandler = new GetPaymentDetailsHandler({});

        const response = await applicationPaymentDetailsHandler.customAction({
          params: arrayToQueryParams(paymentDetailsParamsArray),
        });

        resolve(response);
      } catch (error) {
        if (error.status === 400) {
          redirectTo();
        }

        if (error.status === 412) {
          const orderCookie = getCookie("address_token");
          const regexApplicationPaid = new RegExp("is already paid");
          const regexApplicationPending = new RegExp("has pending transaction");
          setIframeLoaderContainer(true);

          if (regexApplicationPaid.test(error.request.response.message)) {
            // flow application paid: check application status
            if (orderCookie.paymentSite !== orderCookie.originalSite) {
              window.top.location.href = `${orderCookie.originalSite}/thank-you`; // Leave window.top.location.href as is outside of the iframe
            } else {
              window.top.location.href = "/thank-you"; // Leave window.top.location.href as is outside of the iframe
            }
          }

          if (regexApplicationPending.test(error.request.response.message)) {
            // flow application refund: send to
            loadIframeUrl("/verify-payment", null, "verifyPayment");
          }
        }
        throw reject(error);
      }
    });
  };

  const getApplicationData = async () => {
    try {
      const configResponse = await getApplicationInfo();
      const response = await Promise.all([getApplicationName(), getApplicationPaymentDetails()]);

      setApplication({
        ...application,
        applicationId,
        applicationUser: configResponse.username,
        applicationEmail: configResponse.email,
        applicationName: response[0].application.formName,
        applicationNumber: response[0].application.formDescription,
        applicationReference: response[0].application.reference,
        emptyGatways: response[1].paymentGateways < 1,
        paymentGateways: response[1].paymentGateways,
        paymentGatewayDetails: response[1].paymentGatewayDetails,
        applicationPrice: {
          name: response[1].regularPrice.name,
          amount: response[1].regularPrice.amount,
        },
        gatewaysStructure: buildGatewaysStructure(response[1].paymentGateways)
      });

      setCookies("address_token", {
        order: applicationId,
        application: response[0].application,
        regularPrice: response[1].regularPrice,
        paymentSite: response[0].application.paymentSiteWithPrefix,
        originalSite: response[0].application.originalSiteWithPrefix,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  const buildGatewaysStructure = (paymentGateways) => {
    let gatewaysStructure = [];

    Object.keys(paymentGateways).forEach((key, index) => {
      const isMobile = window.matchMedia("(max-width: 790px)");
      let gateway = key;
      let methods = paymentGateways[key];
      let isMastercard = methods.includes("mastercard");
      let isVisa = methods.includes("visa");
      let methodNormalized = methods[0].indexOf("_");
      let methodpart1 = methods[0].slice(0, methodNormalized);
      let methodpart2 = methods[0].slice(++methodNormalized, methodNormalized.length);
      let methodCapitalizedAlt =
        methodpart1.charAt(0).toUpperCase() +
        methodpart1.slice(1) +
        " " +
        methodpart2.charAt(0).toUpperCase() +
        methodpart2.slice(1);

      let methodCapitalized = methods[0].includes("_")
        ? methodCapitalizedAlt
        : methods[0].charAt(0).toUpperCase() + methods[0].slice(1).replace("_", " ");

      let selectItem = {
        icons: [methods],
        name: gateway,
        items: [
          {
            value: isMastercard ? "mastercard" : methods[0],
            label:
              isMastercard && isVisa
                ? "Pay with Visa or Mastercard"
                : `Pay with ${methodCapitalized}`,
            checked: isMobile.matches ? null : isMastercard ? true : false,
          },
        ],
        methods: [methods],
        value: isMobile.matches ? null : isMastercard ? "mastercard" : "",
        key: index,
      };

      gatewaysStructure.push(selectItem);
    });

    const thereIsOneChecked = gatewaysStructure.some(({ items }) =>
      items.some((item) => item.checked)
    );
    if (!thereIsOneChecked && gatewaysStructure.length > 0) {
      gatewaysStructure[0].items[0].checked = true;
    }

    return gatewaysStructure;
  };

  return {
    applicationId,
    application,
    iframeLoaderContainer,
    loading,
    setApplication,
    setLoading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getApplicationData: useCallback(getApplicationData, [application, applicationId]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getApplicationInfo: useCallback(getApplicationInfo, [applicationId]),
  };
};

export default useGetApplication;
